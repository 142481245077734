'use client'
import React, { useEffect } from 'react'

import { Modal } from '@/components/modal/modal'
import { useSkeletonContext } from '@/providers/skeleton/skeleton-context'
import { ProductListItemSkeleton } from '@/components/product-list-item/product-list-item-skeleton'
import { ClientOnlyComponent } from '@/components/wrappers/client-only-component'

export default function ProductPageSkeleton({ Header, Footer }) {
  const { productSkeletonName, isSkeletonOpen, handleCloseSkeleton, target } =
    useSkeletonContext()

  useEffect(() => {
    return () => {
      handleCloseSkeleton()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ClientOnlyComponent>
      <Modal
        isOpen={target === 'product' && isSkeletonOpen}
        withAnimation={false}
        onClose={() => {}}
        className="w-screen min-h-screen top-0 mt-0 p-0 overflow-scroll bg-white"
      >
        <div id="product-skeleton-page" className="flex flex-col min-h-screen">
          {Header}
          <div className="container max-w-screen-desktop mx-auto flex flex-col flex-1 px-5 pb-20 2xl:px-0">
            {/* Breadcrumbs */}
            <div className="w-full max-w-[800px]  h-4 animate-pulse bg-skeleton mt-1" />

            <div className="grid grid-cols-1 md:grid-cols-[1fr_2fr] gap-12 my-4">
              {/* Product slider */}
              <div className="w-full h-full shrink-0 flex justify-center items-center">
                <div className="w-full h-[490px] md:h-full  shrink-0  cursor-pointer object-contain animate-pulse bg-skeleton" />
              </div>
              {/* Product content */}
              <div>
                <h1
                  className="page-title mb-1 text-black font-bold text-lg mt-5"
                  id="product-title"
                >
                  {productSkeletonName}
                </h1>

                {/* Reviews - stars */}
                <div className="w-44 ml-auto  h-4 animate-pulse bg-skeleton" />

                {/* Product description */}
                <div className="w-full  h-24 animate-pulse bg-skeleton mt-4" />

                <div className="md:w-1/2 min-w-fit">
                  {/* Delivery labels */}
                  <div className="flex gap-x-1 flex-col sm:flex-row mt-14 sm:mt-4">
                    <div className="w-24  h-7 animate-pulse bg-skeleton" />
                    <div className="w-52 sm:w-40 h-12 sm:h-7 animate-pulse bg-skeleton" />
                  </div>

                  <div className="flex flex-col mt-5 sm:mt-7 gap-y-1">
                    {/* Product price */}
                    <div className="w-72 sm:w-24 h-8 sm:h-5 animate-pulse bg-skeleton" />
                    {/* Configurable variants */}
                    <div className="mt-0 sm:mt-5 flex flex-col gap-y-2">
                      <div className="w-52  h-4 animate-pulse bg-skeleton" />
                      <div className="w-64 md:w-[450px]  h-10 animate-pulse bg-skeleton" />
                    </div>
                    {/* Buy button */}
                    <div className="w-full mt-4 sm:mt-0  h-14 animate-pulse bg-skeleton" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-16">
              {/*Recommended products */}
              <div className="w-64  h-6 animate-pulse bg-skeleton mb-4" />
              <div className="grid md:grid-cols-4 lg:grid-cols-6 gap-x-5 gap-y-7">
                {[...Array(6)].map((_, index) => {
                  const isDesktop = index >= 4

                  return (
                    <div
                      key={index}
                      data-desktop={isDesktop}
                      className="flex flex-col data-[desktop=true]:md:hidden data-[desktop=true]:lg:block"
                    >
                      <ProductListItemSkeleton />
                    </div>
                  )
                })}
              </div>

              {/*Product tabs */}
              <div className="w-full max-w-[80%] grid grid-cols-6 mt-12 gap-x-2">
                {[...Array(6)].map((_, index) => (
                  <div
                    key={index}
                    className="w-full h-10 animate-pulse bg-skeleton"
                  />
                ))}
              </div>
              <div className="mt-6 w-full h-20 nimate-pulse bg-skeleton" />
              <div className="mt-4 w-full h-96 nimate-pulse bg-skeleton" />
            </div>
          </div>
          {Footer}
        </div>
      </Modal>
    </ClientOnlyComponent>
  )
}
