import { useTranslations } from 'next-intl'

import { cn } from '@/common/utils/lib'
import { Currency } from '@/components/currency'
import { useCartContext, useStoreContext } from '@/providers'
import { STORE_CODES } from '@/common/utils'
import { Domain } from '@/common/types'
import { DeliveryData } from 'src/app/api/delivery-date-info/route'

interface Props {
  deliveryData: DeliveryData | undefined
  productPrice: number | null | undefined
  hasVatShippingMessage: boolean
}

// Stores excluded from standard delivery price calculation and display
export const excludedStores = [
  STORE_CODES.ba,
  STORE_CODES.rs,
  STORE_CODES.ua,
  STORE_CODES.ru,
]

const GENERAL_MARKET_THRESHOLD = 300

export const DeliveryInfo = ({
  deliveryData,
  productPrice,
  hasVatShippingMessage,
}: Props) => {
  const t = useTranslations('Product')
  const { cartItems, isCartRefetching } = useCartContext()
  const {
    storeCode,
    storeConfig: { currency },
  } = useStoreContext()
  const isGeneralMarket = storeCode === STORE_CODES[Domain.COM]
  const isExcludedStore = excludedStores.includes(storeCode)

  const deliveryPrice = deliveryData?.methods.length
    ? Math.min(
        ...deliveryData?.methods
          .filter((item) => item.price !== 0)
          .map((item) => item.price),
      )
    : null

  const deliveryFreeShippingThreshold = isGeneralMarket
    ? GENERAL_MARKET_THRESHOLD
    : deliveryData?.methods.length
    ? Math.min(
        ...deliveryData?.methods
          .filter((item) => item.free_shipping_threshold !== null)
          .map((item) => item.free_shipping_threshold!),
      )
    : null

  const totalSum = cartItems.reduce((acc, item) => {
    return acc + (item?.prices?.row_total_including_tax?.value || 0)
  }, 0)

  if (
    deliveryFreeShippingThreshold === null ||
    isCartRefetching ||
    (!deliveryPrice && !isGeneralMarket)
  ) {
    return null
  }

  const isDeliveryFree =
    totalSum >= deliveryFreeShippingThreshold ||
    (productPrice && productPrice >= deliveryFreeShippingThreshold)

  return (
    <div
      className={cn(
        'flex flex-col text-right',
        (hasVatShippingMessage || isExcludedStore) && 'mt-4',
        !(hasVatShippingMessage || isExcludedStore) && 'items-end',
      )}
    >
      {isDeliveryFree ? (
        <div className="text-xs">{t('detail.price.delivery.free')}</div>
      ) : (
        <div className="text-xs">
          <div>
            {t('detail.price.delivery.from')}{' '}
            <Currency currency={currency} price={deliveryPrice} />
          </div>
          <div>
            {t('detail.price.delivery.freeFrom')}{' '}
            <Currency
              currency={currency}
              price={deliveryFreeShippingThreshold}
            />
          </div>
        </div>
      )}
    </div>
  )
}
