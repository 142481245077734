'use client'
import { useTranslations } from 'next-intl'
import { twJoin, twMerge } from 'tailwind-merge'
import Link from 'next/link'

import { Currency } from '@/components/currency'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Tooltip } from '@/components/tooltip'
import { useAuthContext, useStoreContext } from '@/providers'
import { obsoloteGetPrice, STORE_CODES } from '@/utils'
import { DeliveryInfo, excludedStores } from './delivery-info'
import { UnitPrice } from './unit-price'
import { selectors } from '@/common/constants/selectors-constants'
import { useProductDataContext } from '@/providers/product-data/product-data-context'
import VatAndShippingMessage from './vat-and-shipping-message'
import { DeliveryData } from 'src/app/api/delivery-date-info/route'

type PriceProps = {
  inStock: boolean
  deliveryData?: DeliveryData
  small?: boolean
}

export function Price({
  deliveryData,
  inStock,
  small,
}: PriceProps): JSX.Element | null {
  const t = useTranslations('Product')
  const { customerData } = useAuthContext()
  const { configurableProductVariant } = useProductDataContext()
  const { storeCode } = useStoreContext()
  const {
    showRegularPrice,
    recommendedPrice,
    finalPrice,
    wholesaleDiscountPercentageFormatted,
  } = obsoloteGetPrice({
    price_range: configurableProductVariant?.product?.price_range,
    msrp_price: configurableProductVariant?.product?.msrp_price,
  })

  const unitPrice = configurableProductVariant?.product?.unit_price

  const isB2B = !!customerData?.is_b2b

  const hasVatShippingMessage = storeCode === STORE_CODES.de

  const shouldShowFreeReturns = !excludedStores.includes(storeCode)

  if (inStock) {
    return (
      <div className="mt-4">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <div
              data-test={selectors.PDP.productPrice}
              className="flex gap-4 items-center"
            >
              <Currency
                className={twJoin(
                  'text-secondary',
                  small ? 'text-[15px]' : 'text-lg',
                  'font-bold leading-none',
                )}
                currency={finalPrice?.currency}
                price={finalPrice?.value}
              />
              {isB2B && (
                <div className="cursor-default">
                  <Tooltip
                    direction="right"
                    text={
                      <span className="min-w-fit text-tooltip-text text-xs whitespace-nowrap">
                        {t('detail.price.discount.wholesaleDiscount')}
                      </span>
                    }
                  >
                    <SaleRibbon
                      wholesaleDiscountPercentageFormatted={
                        wholesaleDiscountPercentageFormatted
                      }
                    />
                  </Tooltip>
                </div>
              )}
            </div>

            {hasVatShippingMessage && (
              <VatAndShippingMessage
                hasFreeShipping={hasFreeShipping(
                  finalPrice?.value,
                  deliveryData?.freeShippingThreshold,
                )}
              />
            )}

            {showRegularPrice && (
              <div className="text-grey-300 text-xs flex items-center">
                <Currency
                  className="line-through"
                  currency={recommendedPrice?.currency}
                  price={recommendedPrice?.value}
                />
                <span className="cursor-help inline-flex ml-1">
                  <Tooltip
                    direction="right"
                    text={
                      <span className="w-[210px] inline-block text-tooltip-text">
                        {isB2B
                          ? t('detail.price.discount.recommendedRetailPrice')
                          : t('detail.price.lowestLastPrice')}
                      </span>
                    }
                  >
                    <QuestionMarkRound
                      className="fill-grey-300"
                      width={18}
                      height={18}
                    />
                  </Tooltip>
                </span>
              </div>
            )}

            {unitPrice && <UnitPrice unitPrice={unitPrice} />}
          </div>

          {!isB2B ? (
            <div className="mt-1 flex flex-col items-end">
              <DeliveryInfo
                deliveryData={deliveryData}
                productPrice={finalPrice?.value}
                hasVatShippingMessage={hasVatShippingMessage}
              />
              {shouldShowFreeReturns && (
                <Link
                  href={t('detail.price.delivery.freeReturnsLink')}
                  className="text-grey-700 text-xs flex items-center underline"
                >
                  {t('detail.price.delivery.freeReturns')}
                </Link>
              )}
            </div>
          ) : (
            <div className="mt-1 flex flex-col items-end">
              <DeliveryInfo
                deliveryData={deliveryData}
                productPrice={finalPrice?.value}
                hasVatShippingMessage={hasVatShippingMessage}
              />
            </div>
          )}
        </div>
      </div>
    )
  } else if (finalPrice) {
    return (
      <div className="mt-2 price-estimated">
        <span className={twMerge('label', 'text-lg font-bold')}>
          {t('detail.price.expectedPrice')}&nbsp;
        </span>
        <Currency
          className="text-secondary text-lg font-bold"
          currency={finalPrice?.currency}
          price={finalPrice?.value}
        />
      </div>
    )
  } else {
    return null
  }
}

const SaleRibbon = ({
  wholesaleDiscountPercentageFormatted,
}: {
  wholesaleDiscountPercentageFormatted: string
}) => {
  return (
    <div
      className={`flex items-center justify-center 
                  relative w-[65px] h-[24px] ml-3 rounded-[4px]
                 bg-secondary text-md font-bold text-[white]
                  before:absolute before:w-[18px] before:h-[18px] 
                  before:rotate-45 before:-left-[7px] before:bg-secondary before:rounded-sm`}
    >
      <span>- {wholesaleDiscountPercentageFormatted}</span>
    </div>
  )
}

export const hasFreeShipping = (
  price?: number | null,
  thresholdPrice?: number,
): boolean => {
  if (price === undefined || price === null || thresholdPrice === undefined)
    return false
  return price >= thresholdPrice
}
